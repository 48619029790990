<template>
    <div :className="className">
    </div>
  </template>
    
  <script setup>
    import { computed } from 'vue';
    const props = defineProps({
      dashed: {
        type: String,
        default: ''  // 默认为空字符串
      }
    });
    const className = computed(() => {
    let lineClass = 'artemisads-divid'
    if (props.dashed === 'dashed') {
      lineClass += ' dashed';
    }
      return lineClass;
    })
  </script>
    
    <style lang="less" scoped>
    .artemisads-divid {
      width: 100%;
      height: 1px;
      background-color: var(--border-4);
      &.dashed {
        background-color: transparent;
        border-bottom: 1px dashed var(--border-4);
      }
    }
    </style>
    