<template>
  <a-config-provider
    :theme="{
      token: {
        colorPrimary: '#835CF2',
        borderRadius:'8px',
        fontFamily:'Sora-Medium'
      },
    }"
    >
  <router-view></router-view>
</a-config-provider>
</template>

<script>
  import { theme } from 'ant-design-vue';
  export default {
    name: "App",
    components: {},
  };
</script>