/*
 * @Author: Gang Jiang 
 * @Date: 2024-09-18 11:01:35 
 * @Last Modified by: Gang Jiang
 * @Last Modified time: 2024-09-18 14:53:20
 */

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import Antd from 'ant-design-vue'
import router from './router/router'
import DatePicker from 'ant-design-vue/es/date-picker/moment';
import TimePicker from 'ant-design-vue/es/time-picker/moment';
import Calendar from 'ant-design-vue/es/calendar/moment';
import moment from 'moment-timezone';
import { notification } from 'ant-design-vue';
import '~/assets/images/svg/index.js';

import artemisadsComponents from './artemisadsComponents'
import './assets/less/common.less'
import './assets/less/antd-theme.less'
import './assets/iconfont/iconfont.js'
// import "ant-design-vue/dist/antd.css"
const app = createApp(App);
const pinia = createPinia();

const localStoragePrefix = location.hostname;

// 检查是否有token、token是否过期
const checkUserStatus = () => {
  let isActive = true;
  const userToken = localStorage.getItem(`${localStoragePrefix}_userToken`);
  const tokenTime = Number(localStorage.getItem(`${localStoragePrefix}_tokenTime`));
  const timeNow = new Date().getTime();
  const expirationTime = 1000 * 60 * 60 * 24 * 3; //3天
  if (!userToken || (tokenTime + expirationTime <= timeNow)) {
    localStorage.setItem(`${localStoragePrefix}_userToken`, '');
    localStorage.setItem(`${localStoragePrefix}_tokenTime`, '');
    localStorage.setItem(`${localStoragePrefix}_artmisAdsUserInfo`, '');
    isActive = false;
  }
  return isActive;
}

router.beforeEach((to, from, next) => {
  document.title = to.meta && to.meta.title ? to.meta.title : 'In Artemisads';
  if (to.matched.length) {
    let userInfo = localStorage.getItem(`${localStoragePrefix}_artmisAdsUserInfo`);
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
    } else {
      userInfo = {};
    }
    if (to.meta.requiresAUserType) { // 判断该路由是否需要进行鉴权
      let nextUrl = '';
      if (to.meta.requiresAUserType === userInfo.userType) {
        const isActive = checkUserStatus();
        if (!isActive) {
          if (userInfo.userType === 'admin') {
            nextUrl = '/operation/login'
          } else {
            nextUrl = '/sign-in'
          }
        } else if (userInfo.status === 'PENDING_CHOOSE_TYPE') {
          nextUrl = '/profile'
        }
      } else {
        nextUrl = from.path;
      }
      if (nextUrl) {
        next(nextUrl);
      } else {
        next();
      }
    } else {
      next(); // 不需要进行鉴权，直接进行下一步路由
    }
  } else {
    next('/404');
  }
});

notification.config({
  placement: 'bottomRight',
  bottom: '50px',
  duration: 3,
  rtl: true,
});

moment.tz.setDefault('America/Los_Angeles');

app.config.productionTip = false;
app.use(router).use(Antd).use(DatePicker).use(TimePicker).use(Calendar).use(pinia).use(artemisadsComponents).mount('#app');

