<template>
  <div class="artmisads-number">
    <a-input-number
    @change="onChange"
    v-bind="props"
    >
    <template #upIcon>
      <svg class="icon" aria-hidden="true" font-size="12px">
        <use xlink:href="#icon-jiantou-shang-cu"></use>
      </svg>
    </template>
    <template #downIcon>
      <svg class="icon" aria-hidden="true" font-size="12px">
        <use xlink:href="#icon-jiantou-xia-cu"></use>
      </svg>
    </template>
    </a-input-number>
    <div :class="className"  v-if="props.suffix">{{ props.suffix }}</div>
  </div>
</template>
<script setup>
import {computed} from 'vue';
const props =defineProps(['value','placeholder','suffix','max','min','width','style','size','formatter','parser','step'])
const emit=defineEmits(['blur','focus','change']);
const className = computed(() => {
    let inputClass = 'commission-suffix'
    if (props.size) {
      inputClass += ` ${props.size}`;
    } 

    return inputClass;
  })
const onChange=(e)=>{
  emit('change',e);
}
</script>
<style lang="less" scoped>
    .artmisads-number:deep{
      position: relative;
      .ant-input-number-input{
      font-weight: 500;
      font-size: 16px;
      color:var(--dark-1);
      letter-spacing: 0.4px;
      line-height: 22px;
      }
      .ant-input-number-handler-wrap{
        opacity: 1 !important;
        width: 30px;
      }
      .anticon{
        font-weight: 400;
        font-size: 12px;
        color: var(--dark-2);
        
      }

    }
    .commission-suffix{
      position:absolute;
      top:0;
      right: 38px;
      width: 22px;
      font-weight: 500;
      font-size: 16px;
      color: var(--dark-1);
      letter-spacing: 0.4px;
      height: 32px;
      line-height: 32px;
      z-index: 2;

    }
    .small{
      height: 24px;
      line-height: 24px;
    }
    .large{
      right: 32px;
      height:40px ;
      line-height:40px ;
    }
    
</style>