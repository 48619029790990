<template>
  <a-button :class="className" v-bind="props">
    <slot></slot>
  </a-button>
</template>
<script setup>
  import { computed } from 'vue';

  const className = computed(() => {
    let buttonClass = 'artmisads-button-special';
    return buttonClass;
  })

</script>
<style lang="less" scoped>
  .artmisads-button-special {
    border-radius: 8px;
    text-align: center;
    line-height: 20px;
    height: var(--input-height-middle);
    padding: 6px 12px;
    background-color: var(--purple-3);
    color: var(--dark-1);
    box-shadow: none;
    border: none;
    svg {
      fill: var(--dark-1);
    }
    &:hover {
      background-color: var(--purple-2);
      color: var(--dark-1);
      box-shadow: none;
      border: none;
    }
    &:active {
      background-color: var(--purple);
      color: var(--dark-1);
      box-shadow: none;
      border: none;
    }
  }
</style>
