<template>
  <a-modal
    v-model:open="props.open"
    :class="className"
    @ok="onOk"
    @cancel="onCancel"
    v-bind="props"
  >
    <slot></slot>
    <template #closeIcon>
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#icon-close-cu"></use>
      </svg>
    </template>
    <template #footer v-if="$slots.footer">
      <slot name="footer"></slot>
    </template>
    <template #title v-if="$slots.title">
      <slot name="title"></slot>
    </template>
  </a-modal>
</template>
<script setup>
import { ref, computed } from "vue";
const props = defineProps();
const emit = defineEmits(["ok", "cancel"]);
const mod = ref();
const className = computed(() => {
  let buttonClass = "artmisads-modal";
  return buttonClass;
});
const onOk = () => {
  emit("ok");
};
const onCancel = () => {
  emit("cancel");
};
</script>
<style lang="less">
.artmisads-modal {
  display: block;
  // .artmisads-footer {
  //   display: flex;
  //   justify-content: end;
  //   padding: 12px 24px;
  //   border-top: 1px solid var(--border-4);
  //   > button {
  //     margin-left: 8px;
  //   }
  // }
  .ant-modal-content {
    padding: 0;
    box-shadow: var(--section-box-shadow-3);
    border-radius: 8px;
    .ant-modal-header {
      border-radius: 8px 8px 0 0;
      padding: 16px 50px 12px 24px;
      border-bottom: 1px solid var(--border-4);
      margin: 0;
      .ant-modal-title {
        border-radius: 8px 8px 0 0;
        font-size: 20px;
        color: var(--dark-1);
        letter-spacing: 0.5px;
        line-height: 27px;
      }
    }
    .ant-modal-body {
      padding: 16px 16px 12px 24px;
    }
  }
  .ant-modal-close {
    border-radius: 4px;
    color: var(--dark-3);
    &:hover {
      color: var(--dark-2);
    }
    &:active {
      color: var(--dark-1);
    }
  }
  .ant-modal-footer {
    display: flex;
    justify-content: end;
    padding: 12px 24px;
    border-top: 1px solid var(--border-4);
    > button {
      margin-left: 8px;
      border-radius: 20px;
      font-weight: 500;
      text-align: center;
      line-height: 22px;
      letter-spacing: 0.4px;
      height: var(--input-height-large);
      font-size: var(--font-size-large);
      padding: 8px 29px 10px 29px;
      &:first-of-type {
      background-color: var(--bg-gray-6);
      color: var(--dark-2);
      border-color:transparent ;
      &:hover {
        background-color: var(--bg-gray-4);
      }
      &:active {
        color: var(--dark-1);
        background-color: var(--bg-gray-5);
      }
    }
      &:last-of-type {
        background-color: var(--primary);
        box-shadow: 0 1px 0 0 var(--box-shadow-1);
        color: var(--color-white);
        &:hover {
          background-color: var(--primary-hover);
          box-shadow: 0 2px 0 0 var(--box-shadow-1);
        }
        &:active {
          background-color: var(--primary-active);
          box-shadow: 0 2px 0 0 var(--box-shadow-1);
        }
      }
    }
  }
}
</style>
