<template>
    <span class="anticon anticon-plus">
      <svg class="icon" aria-hidden="true">
        <use :xlink:href="`#icon-${name}`"></use>
      </svg>
    </span>
  </template>
    
    <script>
    export default {
      props: {
        name: {
          type: String,
          required: true,
        },
      },
    };
    </script>
    
    <style>
    .icon {
      width: 1em;
      height: 1em;
    }
    </style>
    