<template>
  <a-input-password @blur="onBlur" @focus="onFocus" :class="className" v-bind="props"/>
</template>
<script setup>
  import { computed, ref } from 'vue';

  const isFocus = ref(false);
  const defaultSize = 'middle'; // large small

  const props = defineProps(['size']);

  const className = computed(() => {
    let inputClass = 'artmisads-input-password'
    if (props.size) {
      inputClass += ` ${props.size}`;
    } else {
      inputClass += ` ${defaultSize}`;
    }
    if (isFocus.value) {
      inputClass += ` artmisads-focus`;
    }
    return inputClass;
  })

  const onFocus = () => {
    isFocus.value = true;
  }

  const onBlur = () => {
    isFocus.value = false;
  }

</script>
<style lang="less" scoped>
  .artmisads-input-password {
    border: var(--border);
    box-shadow: 0 1px 0 0 var(--box-shadow-2);
    border-radius: 8px;
    height: var(--input-height-large);
    font-size: var(--font-size-large);
    background-color: var(--color-white);
    padding: 9px 12px;
    &.ant-input-affix-wrapper-status-error {
      border: 2px solid var(--red-border) !important;
      box-shadow: 0 1px 0 0 var(--box-shadow-2) !important;
      &:hover {
        border-color: var(--red-border) !important;
      }
    }
    > input {
      letter-spacing: 0.4px;
      &::-webkit-input-placeholder {
        color: var(--dark-4);
        letter-spacing: 0.4px;
      }
    }
    
    &:hover {
      border: 1px solid var(--bg-gray-7);
      background-color: var(--bg-gray-1);
      :deep(.ant-input) {
        background-color: var(--bg-gray-1);
      }
    }
    &.artmisads-focus {
      color: var(--dark-1);
      border: 1px solid var(--primary-border-2);
      background-color: var(--color-white);
      box-shadow: var(--section-box-shadow-4);
      :deep(.ant-input) {
        background-color: var(--color-white);
      }
    }
    // &.large {
    //   height: var(--input-height-middle);
    //   border-radius: var(--font-size-mormal);
    //   line-height: 22px;
    //   padding: 3px 24px 7px 24px;
    // }
    // &.small {
    //   height: var(--input-height-small);
    //   border-radius: 11px;
    //   line-height: 12px;
    //   font-size: var(--font-size-mormal);
    //   padding: 4px 20px 6px 20px;
    // }
  }
</style>
