import { createRouter, createWebHashHistory } from 'vue-router'

export default createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import('../pages/home/index.vue'),
      children: [
        {
          path: "/404",
          name: "404",
          component: () => import('../pages/404.vue'),
          meta: {
            title: 'Not Found - In ArtemisAds'
          }
        }
      ],
    },
    {
      path: "/admin",
      name: "admin",
      redirect: '/admin/product',
      component: () => import('../pages/admin/index.vue'),
      meta: {
        requiresAUserType: 'seller'
      },
      children: [
        {
          path: "welcome",
          name: "welcome",
          component: () => import('../pages/admin/welcome/index.vue'),
          meta: {
            title: 'Welcome - In ArtemisAds',
          }
        },
        {
          path: "product",
          name: "product",
          component: () => import('../pages/admin/product/index.vue'),
          meta: {
            title: 'Product - In ArtemisAds',
            namePath: ['product'],
            label: 'Products'
          }
        },
        {
          path: 'brand',
          name: "brand",
          redirect: '/admin/brand/index',
          meta: {
            title: 'Brands - In ArtemisAds',
            namePath: ['brand'],
            label: 'Brands'
          },
          children: [
            {
              path: 'index',
              name: 'brandList',
              component: () => import('../pages/admin/Brands/index.vue'),
              meta: {
                namePath: ['brand', 'brandList'],
                label: 'Brands',
                isNotShowMenu: true
              }
            },
            {
              path: "detail/:id",
              name: "brandDetail",
              component: () => import('../pages/admin/Brands/detail.vue'),
              meta: {
                namePath: ['brand', 'brandDetail'],
                label: 'Brand Name'
              }
            }
          ]
        },
        {
          path: "product/:id",
          name: "Seller Product Detail",
          component: () => import('../pages/admin/product/detail/index.vue'),
          meta: {
            title: 'Product - In ArtemisAds',
          }
        },
      ],
    },
    {
      path: "/operation",
      name: "op_admin",
      redirect: '/operation/users',
      component: () => import('../pages/operation/index.vue'),
      meta: {
        requiresAUserType: 'admin'
      },
      children: [
        {
          path: "dashboard",
          name: "op_dashboard",
          component: () => import('../pages/operation/dashboard/index.vue'),
          meta: {
            title: 'Dashboard - ArtemisAds Op',
          }
        },
        {
          path: "publisher",
          name: "op_Publisher",
          component: () => import('../pages/operation/publisher/index.vue'),
          meta: {
            title: 'Publisher - ArtemisAds Op',
          },
        },
        {
          path: "seller",
          name: "op_Seller",
          component: () => import('../pages/operation/seller/index.vue'),
          meta: {
            title: 'Seller - ArtemisAds Op',
          },
        },
        {
          path: "seller-report",
          name: "op_Seller_Report",
          component: () => import('../pages/operation/seller/sellerReport.vue'),
          meta: {
            title: 'SellerReport - ArtemisAds Op',
          },
        },
        {
          path: "users",
          name: "op_Users",
          component: () => import('../pages/operation/users/index.vue'),
          meta: {
            title: 'Users - ArtemisAds Op',
          },
        },
      ]
    },
    {
      path: "/operation/login",
      name: "op_login",
      component: () => import('../pages/operation/login/index.vue'),
      meta: {
        title: 'Login - ArtemisAds Op',
      }
    },
    // {
    //   path: "/mail",
    //   name: "/mail",
    //   component: () => import('../pages/mailTemplate/mail.vue'),
    //   meta: {
    //     title: 'mail - In ArtemisAds',
    //   }
    // },
    // {
    //   path: "/publisher-rejected-email",
    //   name: "/publisher-rejected-email",
    //   component: () => import('../pages/mailTemplate/publisher-rejected-email.vue'),
    //   meta: {
    //     title: 'mail - In ArtemisAds',
    //   }
    // },
    // {
    //   path: "/publisher-approved-email",
    //   name: "/publisher-approved-email",
    //   component: () => import('../pages/mailTemplate/publisher-approved-email.vue'),
    //   meta: {
    //     title: 'mail - In ArtemisAds',
    //   }
    // },
    {
      path: "/terms-of-service",
      name: "/Terms Of Service",
      component: () => import('../pages/termsOfService/index.vue'),
      meta: {
        title: 'Terms Of Service - In ArtemisAds',
      }
    },
    {
      path: "/privacy-policy",
      name: "/Privacy Policy",
      component: () => import('../pages/privacyPolicy/index.vue'),
      meta: {
        title: 'Privacy Policy - In ArtemisAds',
      }
    },
    {
      path: "/sign-in",
      name: "sign-in",
      component: () => import('../pages/signIn/index.vue'),
      meta: {
        title: 'Sign In - In ArtemisAds',
      }
    },
    {
      path: "/sign-up",
      name: "sign-up",
      component: () => import('../pages/signUp/index.vue'),
      meta: {
        title: 'Sign Up - In ArtemisAds'
      }
    },
    {
      path: "/find-password",
      name: "find-password",
      component: () => import('../pages/forget/index.vue'),
      meta: {
        title: 'Find password - In ArtemisAds'
      }

    },
    {
      path: "/reset-password",
      name: "reset-password",
      component: () => import('../pages/forget/resetPassword.vue'),
      meta: {
        title: 'Reset password - In ArtemisAds'
      }

    },
    {
      path: "/profile",
      name: "profile",
      component: () => import('../pages/profile/index.vue'),
      meta: {
        title: 'Profile - In ArtemisAds',
      }
    },
    {
      path: "/publisher",
      name: "Publisher",
      redirect: '/publisher/products',
      component: () => import('../pages/publisher/index.vue'),
      meta: {
        requiresAUserType: 'publisher'
      },
      children: [
        {
          path: "products",
          name: "Products",
          component: () => import('../pages/publisher/products/index.vue'),
        },
        {
          path: "brands",
          name: "Brands",
          component: () => import('../pages/publisher/brands/index.vue'),
        },
        {
          path: "product-links",
          name: "Links",
          component: () => import('../pages/publisher/links/index.vue'),
        },
        {
          path: "products/:id",
          name: "Product Detail",
          component: () => import('../pages/publisher/products/detail/index.vue'),
        },
        {
          path: "profile",
          name: "Profile | Artemis Ads",
          component: () => import('../pages/publisher/Profile/index.vue'),
          meta: {
            title: "Profile - Artemis Ads",
          }
        }
      ]
    }
  ],
})