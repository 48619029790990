<template>
  <a-input :class="className" v-bind="props">
      <template #addonBefore v-if="$slots.addonBefore">
        <slot name="addonBefore"></slot>
      </template>
      <template #prefix v-if="$slots.prefix">
        <slot name="prefix"></slot>
      </template>
  </a-input>
</template>
<script setup>
  import { computed } from 'vue';

  const defaultSize = 'middle'; // large small

  const props = defineProps(['size', 'atype']);

  const className = computed(() => {
    let inputClass = 'artmisads-input'
    if (props.size) {
      inputClass += ` ${props.size}`;
    } else {
      inputClass += ` ${defaultSize}`;
    }
    if(props.class){
      inputClass += ` ${props.class}`;
    }
    if (props.atype === 'addonBefore') {
      inputClass = 'addonBefore';
      if(props.class){
        inputClass += ` ${props.class}`;
      }
    }
    return inputClass;
  })

</script>
<style lang="less" scoped>
  .addonBefore {
    :deep(.ant-input-group-addon) {
      border: var(--border);
      border-right: none;
      box-shadow: 0 1px 0 0 var(--box-shadow-2);
      background-color: #fff;
      padding: 0 8px;
    }
    :deep(.ant-input) {
      border: var(--border);
      box-shadow: 0 1px 0 0 var(--box-shadow-2);
      border-radius: 0 8px 8px 0;
      height: var(--input-height-large);
      font-size: var(--font-size-large);
      background-color: var(--color-white);
      padding: 9px 12px;
      letter-spacing: 0.4px;
      &::-webkit-input-placeholder {
        color: var(--dark-4);
        letter-spacing: 0.4px;
      }
      &:hover {
        border: 1px solid var(--bg-gray-7);
        background-color: var(--bg-gray-1);
      }
      &:focus {
        color: var(--dark-1);
        border: 1px solid var(--primary-border-2);
        box-shadow: var(--section-box-shadow-4);
        background-color: var(--color-white);
      }
    }
  }
  .artmisads-input {
    border: var(--border);
    box-shadow: 0 1px 0 0 var(--box-shadow-2);
    border-radius: 8px;
    height: var(--input-height-large);
    font-size: var(--font-size-large);
    background-color: var(--color-white);
    padding: 9px 12px;
    letter-spacing: 0.4px;
    &.ant-input-status-error {
      border: 2px solid var(--red-border) !important;
      box-shadow: 0 1px 0 0 var(--box-shadow-2) !important;
      &:hover {
        border-color: var(--red-border) !important;
      }
    }
    &::-webkit-input-placeholder {
      color: var(--dark-4);
      letter-spacing: 0.4px;
    }
    &:hover {
      border: 1px solid var(--bg-gray-7);
      background-color: var(--bg-gray-1);
    }
    &:focus {
      color: var(--dark-1);
      border: 1px solid var(--primary-border-2);
      box-shadow: var(--section-box-shadow-4);
      background-color: var(--color-white);
    }
    // &.large {
    //   height: var(--input-height-middle);
    //   border-radius: var(--font-size-mormal);
    //   line-height: 22px;
    //   padding: 3px 24px 7px 24px;
    // }
    // &.small {
    //   height: var(--input-height-small);
    //   border-radius: 11px;
    //   line-height: 12px;
    //   font-size: var(--font-size-mormal);
    //   padding: 4px 20px 6px 20px;
    // }
  }
</style>
