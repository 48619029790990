const VueAutoRegister = {
  install (Vue) {
    //获取所有的vue文件
    const requireComponent = require.context('./', true, /\.vue$/);
    requireComponent.keys().forEach(fileName => {
      //获取当前遍历的组件
      const componentConfig = requireComponent(fileName);
      //获取当前组件的文件名称 vue文件中声明的name属性 || 文件名称
      const componentName = componentConfig.default.name || fileName.replace(/^\.\/(.*)\.\w+$/, '$1');
      //把文件名称当成组件名称，全局注册
      Vue.component(componentName, componentConfig.default || componentConfig);
    });
  }
};
export default VueAutoRegister;