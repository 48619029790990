<template>
  <div :class="className">
    <a-table v-bind="props">
      <template #bodyCell="props">
        <slot name="bodyCell" v-bind="props"></slot>
      </template>
      <template #customFilterDropdown="props">
        <slot name="customFilterDropdown" v-bind="props"></slot>
      </template>
      <template #customFilterIcon="props">
        <slot name="customFilterIcon" v-bind="props"></slot>
      </template>
      <template #summary="props">
        <slot name="summary" v-bind="props"></slot>
      </template>
      <template #headerCell="props">
        <slot name="headerCell" v-bind="props"></slot>
      </template>
      
      <!-- <template #emptyText>
        <div>无数据</div>
      </template> -->
    </a-table>
    <div
      v-if="pagination"
      class="pagination"
      :style="
        props.align
          ? { 'justify-content': props.align }
          : { 'justify-content': 'flex-end' }
      "
    >
      <a-pagination
        v-model:current="props.pagination.current"
        v-bind="props.pagination"
        @change="handlePageChange"
        @showSizeChange="handSizeChange"
      >
        <template #itemRender="{ type, originalElement }">
          <a v-if="type === 'prev'">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-jiantou-zuo-cu"></use>
            </svg>
          </a>
          <a v-else-if="type === 'next'">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-jiantou-you-cu"></use>
            </svg>
          </a>
          <component :is="originalElement" v-else></component>
        </template>
        <template #buildOptionText="props">
          <span>{{ props.value }} per page</span>
        </template>
      </a-pagination>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, computed } from "vue";
const props = defineProps([
  "columns",
  "data-source",
  "pagination",
  "row-key",
  "row-selection",
  "rowClassName",
  "loading",
  "class",
]);
const emit = defineEmits(["change"]);
const className = computed(() => {
  let tableClass = "artmisads-table";
  if (props.class) {
    tableClass += ` ${props.class}`;
  }
  return tableClass;
});
const handleTableChange = (pagination, filters, sorter) => {
  emit("change", pagination, filters, sorter);
};
const handlePageChange = (page, pageSize) => {
  let pagination = { current: page, pageSize };

  emit("change", pagination);
};
const handSizeChange = (current, pageSize) => {
  let pagination = { current, pageSize };

  emit("change", pagination);
};
</script>
<style lang="less" scoped>
.artmisads-table:deep {
  padding-bottom: 16px;
  .ant-table {
    word-break:break-all;
    width: 100%;
    .ant-table-thead {
      > tr {
        background: var(--table-head-bg);
        // box-shadow: inset 0 -1px 0 0 #CCC7D9;
        border-radius: 8px 8px 0 0;
        height: 40px;
      }
      > tr > th {
        height: 40px;
        background: var(--table-head-bg);
        border-bottom: 1px solid var(--border-1);
        font-weight: 500;
        color: var(--dark-2);
        letter-spacing: 0.4px;
        line-height: 40px;
        padding-top: unset;
        padding-bottom: unset;
      }
    }
    .ant-table-tbody {
      tr > td {
        border-top: unset;
        // border-top: 1px solid #E8E5ED !important;
        border-bottom: 1px solid var(--border-4);
      }
      > tr.ant-table-row:hover > td {
        background: var(--table-hover);
      }
    }
    .ant-table-row-selected {
      tr > td {
        background-color: rgba(114, 64, 255, 0.08);
        // &:hover{
        //   background: rgba(114, 64, 255, 0.14) !important;
        // }
      }
      // tr:hover{
      //   background: rgba(114, 64, 255, 0.14) !important;
      // }
      .ant-table-cell-row-hover {
        background: rgba(114, 64, 255, 0.14) !important;
      }
    }
    .ant-checkbox-inner {
      border: 1px solid var(--border-1);
      box-shadow: 0 1px 0 0 rgba(15, 6, 41, 0.05);
    }
    .ant-checkbox-wrapper {
      &:hover,&:active {
        .ant-checkbox-inner {
          border: 1px solid var(--primary-border-2);
          box-shadow: var(--section-box-shadow-4);
        }
      }
    }
    .ant-checkbox-indeterminate{
      .ant-checkbox-inner{
        background-color:var(--primary);
        border: unset !important;
        box-shadow: unset !important;
        &::after{
          height: 2px;
          background-color: #fff;
        }
      }
      &:hover{
        .ant-checkbox-inner{
            background-color:var(--primary-hover);
        }
      }
      &:active{
        .ant-checkbox-inner{
            background-color:var(--primary-active);
        }
      }

    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        border: unset !important;;
        // box-shadow: var(--section-box-shadow-4);
        background-color: var(--primary);
      }
      &:hover {
        .ant-checkbox-inner {
          border: unset !important;;
          box-shadow: unset !important;
          background-color: var(--primary);
        // background-color: var(--primary-border-2);
        }
      }
      &:after{
        display: none;
      }
    }
  }
  .ant-table-pagination {
    display: none;
  }
}

.pagination:deep {
  display: flex;
  height: 40px;
  align-items: center;
  margin-top: 16px;
  a {
    color: inherit;
  }
  .ant-pagination-disabled {
    a {
      color: var(--dark-4);
    }
  }
  .ant-pagination-prev,.ant-pagination-next{
    border: 1px solid transparent;
    font-size: 20px;
  }
  .ant-pagination-prev{
    margin-right: 12px;
  }
  .ant-pagination-next{
    margin-left: 4px;
  }
  .ant-pagination-item {
    color: var(--dark-2);
    &:hover {
      background-color: var(--bg-gray-1);
      border: 1px solid var(--border-1);
      box-shadow: 0 1px 0 0 var(--box-shadow-2);
    }
    &:active {
      background-color: var(--bg-gray-2);
      border-color: var(--bg-gray-7);
      box-shadow: 0 1px 0 0 var(--box-shadow-2);
    }
  }
  .ant-pagination-item-active {
    box-shadow: 0 1px 0 0 var(--box-shadow-2);
    background-color: var(--primary) !important;
    a {
      color: var(--color-white) !important;
    }
    &:hover {
      border-color: transparent;
    }
    &:active {
      border: unset;
    }
  }

  .ant-select {
    border-radius: 8px;
    border: 1px solid var(--border-1);
    box-shadow: 0 1px 0 0 var(--box-shadow-2);
    .ant-select-selector {
      background-color: #fff;
      color: var(--dark-2);
      border: unset;
    }
    &:hover{
      background-color: var(--bg-gray-1);
      color: var(--dark-1);
      border-color: var(--bg-gray-7);
      .ant-select-selector{
        background-color: var(--bg-gray-1);
        color: var(--dark-1);
        border: unset;
      }
      .ant-select-arrow {
        &::after {
          opacity: 0.9;
        }
      }
    }
    &:active {
      border-color: var(--bg-gray-7);
      background-color: var(--bg-gray-2);
      .ant-select-selector {
        background-color: var(--bg-gray-2);
        color: var(--dark-1);
        border: unset;
      }
      .ant-select-arrow {
        &::after {
          opacity: 0.9;
        }
      }
    }
    &:focus {
      border-color: var(--primary-border);
      box-shadow: var(--section-box-shadow-4);
      background-color: #fff;
      .ant-select-selector {
        background-color: #fff;
        color: var(--dark-1);
        border: unset;
      }
      .ant-select-arrow {
        &::after {
          opacity: 0.9;
        }
      }
    }
  }
  .ant-select-focused {
    border: 1px solid var(--primary-border-2);
    box-shadow: var(--section-box-shadow-4);
    background-color: var(--color-white);
    .ant-select-selector {
      border: unset !important;
      box-shadow: unset !important;
      background-color: #fff;
      color: var(--dark-1);
      &:active,&:hover,
      &:focus {
        border: unset !important;
        box-shadow: unset !important;
        background-color: #fff;
        color: var(--dark-1);
      }
    }
    .ant-select-arrow {
      opacity: 0.9;
    }

    &:hover,
    &:active,
    &:focus {
      box-shadow: var(--section-box-shadow-4);
      background-color: #fff;
      color: var(--dark-1);
      .ant-select-selector {
        border: unset !important;
        background-color: #fff !important;
        &:hover{
          border: unset !important;
          background-color: #fff;
        }
      }
    }
  }
  .ant-select-selector {
    min-width: 145px;
  }
  .ant-select-arrow {
    position: absolute;
    color: var(--dark-3);
    visibility: hidden;
    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: 3;
      top: -1px;
      right: 0;
      width: 16px;
      height: 16px;
      background: url(../assets/images/svg/jiantou-down.png);
      opacity: 0.7;
      background-size: 100%;
      visibility: visible;
    }
  }
  .ant-select-dropdown {
    padding: 6px 8px 8px 8px;
    box-shadow: var(--section-box-shadow);
    .ant-select-item {
      margin-top: 2px;
      color: var(--dark-1);
      font-weight: 400;
      &:hover {
        background: var(--bg-gray-6);
      }
      &.ant-select-item-option.ant-select-item-option-selected {
        background-color: var(--select-bg-1);
        position: relative;
        &::after {
          content: "";
          position: absolute;
          right: 8px;
          top: 8px;
          width: 16px;
          height: 16px;
          background: url(../assets/images/svg/select_gou.png);
          background-size: 100%;
        }
      }
    }
  }
}
</style>
